<template>
  <div class="card rounded-5 p-0 col-12 col-md-12 col-lg-5 col-xl-4">
    <div class="header">
      <NuxtImg width="132" v-if="!!affiliateCustomizations && !!appNavLogo" :src="formatImagePath(appNavLogo)" />
      <NuxtImg v-else src="/images/logo-default-dark.svg" width="132" alt="Lendio" />
    </div>
    <div class="card-body">
      <h1 class="title mb-4">
        <slot name="title" />
      </h1>
      <Alert ref="warningBanner" class="mb-5" />
      <Alert ref="alert" class="mb-5" />
      <main class="main mb-5">
        <slot name="main" />
      </main>
      <div class="actions">
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useAffiliateCustomizationStore } from '~/store/affiliate-customization'
import Alert from '../Alert.vue'
import formatImagePath from '~/libs/image-format'

export default {
  name: 'IdentityCard',
  components: [
    Alert,
  ],
  async setup() {
    return {
      formatImagePath,
    }
  },
  computed: {
    ...mapState(useAffiliateCustomizationStore, [
      'affiliateCustomizations',
      'appNavLogo',
    ]),
  },
}
</script>

<style lang="scss" scoped>
@import "lendio-card";
.card {
  max-width: 428px;
}
@media (max-width: 768px) {
  .card {
    height: 100vh;
    border-radius: 0px !important;
    max-width: 100vh;
  }
}
.header {
  padding: 24px;
  border-bottom: 1px solid #E5E0DD;
}
.title {
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  text-align: left;
}
.card-body {
  padding: 24px;
}
</style>
